<template>
  <div class="page posters">
    <div class="section-header" id="pageTop">
      <h1>Posters</h1>
      <h2 class="section-tagline rot-2">Duplicate. Disseminate. Agitate</h2>
      <p class="section-intro">
        The posters can be printed from your computer, hung up in your workplace
        cafeteria or school lunchroom, sheet-mulched into your neighborhood
        carbon capturing food forest or slipped into the magazine rack at a
        freeway filling station.
      </p>
    </div>
    <!-- POSTERS -->
    <div class="posters-container">
      <PosterItem
        v-for="poster in posters"
        :key="poster.id"
        :id="poster.id"
        :pathShort="poster.pathShort"
        :pathLong="poster.pathLong"
        :totalNumPosters="posters.length"
        ,
      />
    </div>
  </div>
</template>

<script>
import PosterItem from "@/components/PosterItem.vue";
// import content from "!raw-loader!@/page-contents/shows-and-events.md";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    const siteData = reactive({
      title: `DEAR CLIMATE: Posters`,
      description: `DUPLICATE. DISSEMINATE. AGITATE`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:description`,
          content: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
        },
        {
          property: `og:site_name`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `http://www.dearclimate.net`,
        },
        {
          property: `og:image`,
          content: `http://www.dearclimate.net/dear-climate-og-img.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: `http://www.dearclimate.net/Posters`,
        },
        {
          name: `twitter:title`,
          content: computed(() => siteData.title),
        },
        {
          name: `twitter:description`,
          content: computed(() => siteData.description),
        },
        {
          name: `twitter:image`,
          content: `http://www.dearclimate.net/dear-climate-og-twitter-img.jpg`,
        },
      ],
    });
  },

  name: "Posters",
  components: {
    PosterItem,
  },
  data: function () {
    return {
      posters: [],
    };
  },
  methods: {
    fetchPosters(r) {
      // console.log(`fetchPosters() called with:`);
      // console.log(r);
      let id = 1;
      r.keys().forEach((key) => {
        // console.log(key);
        this.posters.push({ id: id, pathLong: r(key), pathShort: key });
        id++;
      });
      // console.log("Getting data for posters.");
    },
  },
  mounted() {
    this.fetchPosters(
      require.context("../../public/posters-800px-jpegs/", true, /\.jpg$/)
    );
  },
};
</script>

<style lang="scss">
.page {
  &.posters {
    max-width: 1400px;
  }
}
</style>
