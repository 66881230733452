<template>
  <div class="poster-item" :key="id">
    <div class="poster-overlay">
      <a :href="pdfPath" target="_blank" class="button sml cta-black"
        >download</a
      >
    </div>
    <img :src="pathLong" :alt-text="pathShort" class="poster-image" />
  </div>
  <PosterControls v-if="isEighth" :totalNumPosters="totalNumPosters" />
</template>

<script>
import PosterControls from "@/components/PosterControls.vue";

export default {
  name: "PosterItem",
  props: {
    id: Number,
    pathShort: String,
    pathLong: String,
    totalNumPosters: Number,
  },
  components: {
    PosterControls,
  },
  computed: {
    isEighth() {
      if (this.id % 8 === 0) {
        return true;
      } else {
        return false;
      }
    },
    pdfPath() {
      // console.log(`pathShort: ${this.pathShort}`);
      const pdfName = String(this.pathShort)
        .replace(/.\//gi, "")
        .replace(/.jpg/gi, ".pdf");
      // console.log(`pdfName: ${pdfName}`);
      const pdfPath = "../posters-pdfs/" + pdfName;
      // console.log(`pdfPath: ${pdfPath}`);
      return pdfPath;
    },
  },
};
</script>

<style lang="scss" scoped>
.poster-item {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  margin: 20px;
  padding: 0;
  transition: transform 400ms;
  .poster-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: calc(100% - 8px);
    display: none;
    align-items: center;
    justify-content: center;
  }
  .poster-image {
    width: 300px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }
  &:hover {
    transform: rotate(-2deg);
    .poster-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
