<template>
  <div class="page poster-controls">
    <hr />
    <div>
      <a class="button" href="/Dear-Climate-PDF-Posters.zip" target="_blank">
        Download all {{ totalNumPosters }} PDFs
      </a>
    </div>
    <div class="cc-notice">
      <h3>
        All these posters are available as scalable pdf images, free of charge.
      </h3>
      <p>
        We use the Creative Commons license
        <a
          href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
          target="_blank"
          >'Attribution-NonCommercial-NoDerivatives (CC BY-NC-ND)' </a
        >.
      </p>
      <p>
        This license allows reusers to copy and distribute the material in any
        medium or format in unadapted form only, for noncommercial purposes
        only, and only so long as attribution is given to the creator.
      </p>
    </div>
    <div><a href="#pageTop" target="_self">back to top</a></div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "PosterControls",
  props: {
    totalNumPosters: Number,
  },
};
</script>

<style lang="scss" scoped>
.poster-controls {
  hr {
    color: black;
    margin: 8rem 4rem;
  }
  .cc-notice {
    border: 1px solid black;
    max-width: 600px;
    margin: 4rem auto;
    padding: 4rem;
    h3 {
      font-size: 2.2rem;
    }
  }
  .button {
    color: white;
    background-color: black;
    border: 1px solid black;
    font-size: 1.4rem;
  }
}
</style>
